.sider {
  min-height: 100vh;
  width: 256px;
  .logo {
    height: 64px;
    line-height: 64px;
    position: relative;
    transition: all 0.3s;
    background: #002140;
    overflow: hidden;
    padding-left: 24px;
    img {
      display: inline-block;
      vertical-align: middle;
      height: 32px;
      border-radius: 4px;
    }
    h1 {
      color: #ffffff;
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      padding: 0;
      margin-left: 18px;
    }
  }
}
