.header {
  height: 64px;
  padding-right: 12px;
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color .3s;
    &:hover {
      color: #1890ff;
    }
  }
  .right {
    float: right;
    height: 100%;
    margin-right: 12px;
    .avatar {
      background: rgba(255, 255, 255, 0.85);
      cursor: pointer;
      padding: 0 12px;
      display: inline-block;
      display: flex;
      transition: all 0.3s;
      height: 100%;
      &:hover,&:global(.ant-popover-open) {
        background: #e6f7ff
      }
    }
    .namecls {
      padding: 0 15px;
      color: rgba(0,0,0,0.85);
    }
  }
}
.menu {
  :global(.ant-dropdown-menu-item) {
    width: 108px;
  }
}
