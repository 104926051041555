.login {
  width: 100%;
  :global {
    .ant-form .ant-form-item-control {
      margin-bottom: 6px;
    }
    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding-left: 34px;
    }
  }
}