@mixin textOverflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@mixin line-ellipsis-x($lines){
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
@mixin bg-image($url) {
  background-image: url($url + "@2x.png");
  @media (-webkit-min-device-pixel-ratio:3),(min-device-pixel-ratio:3){
    background-image: url($url + "@3x.png")
  }
}
$backGround: #f7f7f7;
$theme: #07BFE3;
$common: #595959;
$weak: #9c9c9c;
$weaker: #c6c6c6;
$borderColor: #e8e8e8;
$error: #FF1A2E;

@mixin borderBottom {
  border-bottom: 1px solid $borderColor;
}
@mixin pressOpacity {
  &:active{
    opacity: 0.5;
  }
}
::-webkit-input-placeholder{
  color: $weak;
}
:-moz-placeholder{
  color: $weak;
}
::-moz-placeholder{
  color: $weak;
}
:-ms-input-placeholder{
  color: $weak;
}
