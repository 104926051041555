@import '../../styles/constant.scss';

.avatar-container {
  width: 50px;
  height: 50px;
  position: relative;
  .avatar-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .verified-icon{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
  }
}
